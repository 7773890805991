import Quill from 'quill';

import Utils from "../../../utils";
import DefaultController from "../../defaultController";

export default class SettingsTemplatesList extends DefaultController{

    private editors: any[] = []

    async init() {
        this.editors = []
        await this.bindListeners();
        console.log(this.editors)
        await Utils.hideLoader();
    }

    async bindListeners() {
        for (const elem of (document.querySelectorAll(".editor-wrapper") as NodeListOf<HTMLElement>)) {
            const kind = elem.getAttribute("data-kind")
            const editorElem = elem.querySelector(".editor") as HTMLElement
            const toolbarElem = elem.querySelector(".toolbar") as HTMLElement

            const editor = new Quill(editorElem, {
                modules: {
                    toolbar: toolbarElem
                },
                theme: 'snow'
            });

            (elem.querySelector(".ql-ticket-link") as HTMLButtonElement).addEventListener('click', () => {
                const range = editor.getSelection();
                if (range) {
                    editor.insertText(range.index, "[TICKET]");
                }
            });

            (elem.querySelector(".ql-ticket-assignee") as HTMLButtonElement).addEventListener('click', () => {
                const range = editor.getSelection();
                if (range) {
                    editor.insertText(range.index, "[TICKET-ASSIGNEE]");
                }
            });

            (elem.querySelector(".ql-ticket-status") as HTMLButtonElement).addEventListener('click', () => {
                const range = editor.getSelection();
                if (range) {
                    editor.insertText(range.index, "[TICKET-STATUS]");
                }
            });

            (elem.querySelector(".editor_save") as HTMLButtonElement).addEventListener('click', async (e) => {
                e.preventDefault();
                const content = editor.root.innerHTML;
                const uuid = (elem.querySelector(".mail_template_uuid") as HTMLInputElement).value
                const r = await Utils.entity.upsert({
                    uuid: uuid,
                    kind: kind,
                    content: content
                }, "mail_templates");
                if (r.status === 200) {
                    this.toastr.success(`${Utils.translate('template.name')} ${Utils.translate('generic.saved')}`, `${Utils.translate('generic.success')}`)
                } else {
                    this.toastr.error(`${Utils.translate('template.name')} ${Utils.translate('generic.messages.not_updated')}`, `${Utils.translate('generic.error')}`)
                }
            });


            this.editors.push(editor)
        }
    }
}